import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactGlobal from "components/ContactGlobal"

import {
  Hero,
  Nav,
  About,
  AboutWithBg,
  Timeline,
  Icons,
  Grid,
  Tiles,
  Convenients,
  Video,
  Map,
  Apartments,
  Gallery,
  News,
  Offer,
  Popup,
} from "page_components/investment"

const Investment = ({ pageContext, data }) => {
  const region = pageContext?.data?.data?.acfInvestment?.region
  const fullSchema = JSON.parse(pageContext?.data?.data?.seo?.schema?.raw)

  const getRegion = region => {
    switch (region) {
      case "mountains":
        return {
          label: "w górach",
          title: "Inwestycje w górach",
          path: "/portfel-nieruchomosci/inwestycje-w-gorach/",
        }
      case "sea":
        return {
          label: "nad morzem",
          title: "Inwestycje nad morzem",
          path: "/portfel-nieruchomosci/inwestycje-nad-morzem/",
        }
      case "lakes":
        return {
          label: "warmia i mazury",
          title: "Warmia i Mazury",
          path: "/portfel-nieruchomosci/warmia-i-mazury/",
        }
      default:
        return
    }
  }

  const currentRegion = getRegion(region)

  return (
    <Layout
      seo={{
        title: pageContext?.data?.data?.seo.title
          ? pageContext?.data?.data?.seo.title
          : pageContext?.data?.title,
        description: pageContext?.data?.data?.seo.metaDesc
          ? pageContext?.data?.data?.seo.metaDesc
          : "Inwestycje w apartamenty premium. Zarabiaj inwestując w apartamenty inwestycyjne, hotelowe, komercyjne.",
      }}
      thankYouSlug={`/dziekujemy-${pageContext?.data?.data?.slug}/`}
    >
      <Breadcrumbs
        title={pageContext?.data?.title}
        subParent={{
          title: "Portfel nieruchomości",
          path: "/portfel-nieruchomosci/",
        }}
        parent={{
          title: currentRegion?.title,
          path: currentRegion?.path,
        }}
      />
      <Hero
        id={pageContext?.data?.id}
        title={pageContext?.data?.title}
        data={pageContext.data.data.acfInvestment}
        region={currentRegion}
        slug={pageContext?.data?.data?.slug}
      />
      <Nav
        title={pageContext?.data?.title}
        data={pageContext.data.data.acfInvestment}
      />
      <AboutWithBg data={pageContext.data.data.acfInvestment} />
      <About data={pageContext.data.data.acfInvestment} />
      <Timeline data={pageContext.data.data.acfInvestment} />
      <Icons data={pageContext.data.data.acfInvestment} />
      <Grid data={pageContext.data.data.acfInvestment} />
      <Tiles data={pageContext.data.data.acfInvestment} />
      <Convenients data={pageContext.data.data.acfInvestment} />
      <Video data={pageContext.data.data.acfInvestment} />
      <Map data={pageContext.data.data.acfInvestment} />
      <Apartments
        data={pageContext.data.data}
        dataAcf={pageContext.data.data.acfInvestment}
      />
      <Gallery data={pageContext.data.data.acfInvestment} />
      <News
        id={pageContext.data?.id}
        data={pageContext.data.data.acfInvestment}
        allWpPost={data?.allWpPost?.nodes}
      />
      <Offer
        data={pageContext.data.data.acfInvestment}
        allWpInvestment={data?.allWpInvestment?.nodes}
      />
      <ContactGlobal
        slug={pageContext?.data?.data?.slug}
        investment={pageContext?.data?.title}
        partialSubjects
      />
      <Popup
        id={pageContext.data?.id}
        data={pageContext.data.data.acfInvestment}
      />

      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpInvestment(
      filter: { acfInvestment: { status: { eq: "sell" } } }
      sort: { fields: date, order: ASC }
    ) {
      nodes {
        id
        title
        uri
        slug
        acfInvestment {
          apartmensNumber
          areaNumberMin
          areaNumberMax
          floorsNumberMin
          floorsNumberMax
          roomsNumberMin
          roomsNumberMax
          apartmenPriceMin
          apartmenPriceMax
          address
          status
          region
          logo {
            localFile {
              publicURL
            }
          }
          images {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        uri
        excerpt
        date
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        acfPost {
          showInvestments {
            ... on WpInvestment {
              id
              uri
              title
            }
          }
        }
      }
    }
  }
`

export default Investment
